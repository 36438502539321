//NEED IMPORT LOCALIZE !!!

import guestMiddleware from "./middleware/guestMiddleware.js"
// import guestMiddleware from "./middleware/guestMiddleware";
// import adminMiddleware from "@/router/middleware/adminMiddleware";
// import authMiddleware from "@/router/middleware/authMiddleware";

const routes = [
  {
    path: '*',
    name: 'error',
    meta: {
      layout: "error",
    },
    component: () => import('../views/Error.vue')
  },
  {
    path: '/',
    redirect: '/home',
    name: 'main',
    meta: {
      layout: "main",
      label: "Главная",
    },
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      layout: "main",
      label: "Главная",
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta: {
      layout: 'main',
      label: 'Описание проекта',
    }
  },
  {
    path: '/organization',
    name: 'organization',
    component: () => import('../views/settings/organization/Organization.vue'),
    meta: {
      layout: 'main',
      label: 'Организация',
    }
  },
  {
    path: '/accesses',
    name: 'accesses',
    component: () => import('../views/accesses/Accesses.vue'),
    meta: {
      layout: 'main',
      label: 'Управление доступами',
    }
  },
  {
    path: '/offer',
    name: 'offer',
    component: () => import('../views/settings/offer/Offer.vue'),
    meta: {
      layout: 'main',
      label: 'Услуги',
    }
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import('../views/settings/cash/Cash.vue'),
    meta: {
      layout: 'main',
      label: 'Касса',
    }
  },
  {
    path: '/person',
    name: 'person',
    component: () => import('../views/person/Person.vue'),
    meta: {
      layout: 'main',
      label: 'Пациенты',
    }
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/pdf/PdfView.vue'),
    meta: {
      layout: 'main',
      label: 'Test',
    }
  },
  {
    path: '/direction',
    name: 'direction',
    component: () => import('../views/direction/Direction.vue'),
    meta: {
      layout: 'main',
      label: 'Направление',
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/payment/Payment.vue'),
    meta: {
      layout: 'main',
      label: 'Оплата',
    }
  },
  {
    path: '/shift',
    name: 'shift',
    component: () => import('../views/shift/Shift.vue'),
    meta: {
      layout: 'main',
      label: 'Итоги продаж',
    }
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../views/report/ReportView.vue'),
    meta: {
      layout: 'main',
      label: 'Отчет',
    }
  },
  {
    path: '/pharmacy',
    name: 'pharmacy',
    component: () => import('../views/pharmacy/PharmacyView.vue'),
    meta: {
      layout: 'main',
      label: 'Аптека',
    }
  },
  {
    path: '/queue-monitor',
    name: 'queue-monitor',
    component: () => import('../views/queue/QueueView.vue'),
    meta: {
      layout: 'main',
      label: 'Электронная очередь',
    }
  },
  {
    path: '/queue-terminal',
    name: 'queue-terminal',
    component: () => import('../views/queue/QueueOfferView.vue'),
    meta: {
      layout: 'main',
      label: 'Электронная очередь терминал',
    }
  },
  {
    path: '/settings-report',
    name: 'settings-report',
    component: () => import('../views/settings/report/SettingsReport.vue'),
    meta: {
      layout: 'main',
      label: 'Отчет',
    }
  },
  {
    path: '/settings-report/:uid',
    name: 'settings-report-detail',
    component: () => import('../views/settings/report/detail/SettingsReportDetail.vue'),
    meta: {
      layout: 'main',
      label: 'Отчет',
    }
  },
  {
    path: '/settings-template',
    name: 'settings-template',
    component: () => import('../views/settings/template/Template.vue'),
    meta: {
      layout: 'main',
      label: 'Шаблон',
    }
  },
  {
    path: '/settings-queue',
    name: 'settings-queue',
    component: () => import('../views/settings/queue/Queue.vue'),
    meta: {
      layout: 'main',
      label: 'Электронная очередь',
    }
  }
];

export default routes;
