import {Inject, Service} from "vue-typedi";
import tokens from "@/service/tokens";
import Vue from "vue";
import NavigationItem from "@/model/navigation";
import AccessesService from "@/service/accessesService";

export default interface NavigationService {
  getNavigationItemsByCurrentUser () : Promise<NavigationItem[]>;
  getAdminAccesses () : NavigationItem[];
}

@Service(tokens.NAVIGATION_SERVICE)
export default class NavigationServiceImpl implements NavigationService{

    @Inject(tokens.ACCESSES_SERVICE)
    private accessService: AccessesService;

    items: NavigationItem[]  = [
      new NavigationItem("PERSON", "PERSON_TITLE", "mdi-account-supervisor", "/person", []),
      new NavigationItem("DIRECTION", "DIRECTION_TITLE", "mdi-file-document", "/direction", []),
      //new NavigationItem("PAYMENT", "PAYMENT_TITLE", "mdi-account-credit-card", "/payment", []),
      new NavigationItem("SHIFT", "SHIFT_TITLE", "mdi-sigma", "/shift", []),
      new NavigationItem("REPORT", "REPORT_TITLE", "mdi-chart-bar", "/report", []),
      new NavigationItem("PHARMACY", "PHARMACY_TITLE", "mdi-pill", "/pharmacy", []),
      new NavigationItem("QUEUE", "QUEUE_TITLE", "mdi-human-queue", "", [
        new NavigationItem("QUEUE_SCREEN", "QUEUE_SCREEN_TITLE", "mdi-human-queue", "/queue-monitor", []),
        new NavigationItem("QUEUE_TERMINAL", "QUEUE_TERMINAL_TITLE", "mdi-receipt-text", "/queue-terminal", []),
      ]),
      new NavigationItem("ACCESS", "ACCESS_TITLE", "mdi-shield-account", "/accesses", []),
      new NavigationItem("SETTINGS", "SETTINGS_TITLE", "mdi-cog", "",
        [
          new NavigationItem("OFFER", "OFFER_TITLE", "mdi-toolbox", "/offer", []),
          new NavigationItem("CASH", "CASH_TITLE", "mdi-cash-register", "/cash", []),
          new NavigationItem("ORGANIZATION", "ORGANIZATION_TITLE", "mdi-office-building-plus", "/organization", []),
          new NavigationItem("SETTINGS_REPORT", "SETTINGS_REPORT_TITLE", "mdi-chart-box-plus-outline", "/settings-report", []),
          new NavigationItem("SETTINGS_TEMPLATE", "SETTINGS_TEMPLATE_TITLE", "mdi-text-box-outline", "/settings-template", []),
          new NavigationItem("SETTINGS_QUEUE", "SETTINGS_QUEUE_TITLE", "mdi-human-queue", "/settings-queue", []),
          ]
      )
    ]
    itemsDefault: NavigationItem[] = [
        new NavigationItem("empty", "empty_TITLE", "mdi-eye", "",[])
    ]
  async getNavigationItemsByCurrentUser () : Promise<NavigationItem[]> {
        const roles = await this.accessService.getCurrentUserAccesses(Vue.prototype.$myStore.catalog.currentCatalog)


        if(Vue.prototype.$myStore.catalog.currentCatalog){
            switch (Vue.prototype.$myStore.catalog.currentCatalog.role) {
              case "ADMIN" :
                return this.items
              case "USER":
                if(roles.filter(r => r == "ADMIN").length > 0)
                  return this.items;

                return this.items.filter(r => roles.filter(d => d == r.name).length > 0)
            }
        }
        return this.itemsDefault
    }

  getAdminAccesses () : NavigationItem[] {
    return this.items
  }
}
