import jwtParser from "@/utils/jwtParser"
import session from "@/utils/clientSession/session";
import cookie from "@/utils/clientSession/cookie";

export default function catalogs() : any {
    const value : string = cookie.get("access_token")
    if (!value) return session.logout()
    // if (cookie timeout check) return session.logout()
    //const parsed: any = jwtParser.parse(value)
    return JSON.parse(cookie.get('catalogs'))
}
