import CloudApi from "@/service/api/cloud/cloud.api";

const api = new CloudApi()

export default interface CloudService {
  auth (catalog: string) : Promise<any>
  catalogs() : Promise<any>
}

export default class CloudServiceImpl implements CloudService {

  async auth(catalog: string): Promise<any> {
    return await api.auth(catalog);
  }

  async catalogs(): Promise<any> {
    return await api.getCatalogs();
  }

}
