import fetch from "@/utils/fetch";

export default class CloudApi {

  public async auth(catalog: string) {
    return await fetch.authGet("/api/auth/sign-in-app", {catalog: catalog})
  }

  public async getCatalogs(){
    return await fetch.authGet("/api/auth/claims")
  }
}
