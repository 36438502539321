import auth from "@/utils/clientSession/auth";
import CloudService from "@/service/cloud/cloud.service";
import jwtParser from "@/utils/jwtParser";
import cookie from "@/utils/clientSession/cookie";

export default () => {
    return new Promise( (resolve : any) => {
        let catalog : string = ""
        catalog = location.pathname.substring(1).split('/')[0];

        if (process.env.VUE_APP_AUTH_METHOD === 'COOKIE' && catalog === 'cookie') {
            const redirectUrl = auth.cookie()

            const service = new CloudService();
            service.catalogs()
            .then((catalogs: any) => {
              cookie.set('catalogs', JSON.stringify(catalogs))
              cookie.set('sysname', catalogs[0].catalog)
              service.auth(catalogs[0].catalog).then(res => {
                cookie.set("access_token", res.accessToken)
              })
            }).finally(() => {
              setTimeout(() => {
                return location.replace(redirectUrl)
              }, 1000)
            })
        }
        else if (catalog === "404") return resolve()
        else
        resolve(catalog)

    })
}
